import { FaCircle } from 'react-icons/fa'
import React from 'react'

interface FirstColumnProps {
  data: string
  showIcon: boolean
}
const FirstColumn: React.FC<FirstColumnProps> = ({ data, showIcon }) => {
  if (showIcon) {
    return (
      <span>
        {data} <FaCircle />
      </span>
    )
  }
  return <span>{data}</span>
}
export default FirstColumn
